import React, { useContext } from "react";

import { CartContext } from "../context/CartContext";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { priceComma } from "utils/priceComma";

const CheckoutPrice = ({ checkoutPage, total }) => {
  const { cart, setCart } = useContext(CartContext);

  const [t] = useTranslation("global");
  return (
    <div class=" bg-gray-200 px-4 py-4">
      <p class="text-xl  text-gray-900 mb-[0.5rem]">{t("checkout")}</p>
      {cart?.map((item) => (
        <div class=" border-t border-b py-2">
          <p class="text-lg font-bold text-gray-900 my-2">{item.name}</p>

          <div class="flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">{t("price")}</p>
            <p class="font-semibold text-gray-900" dir="rtl">
            {t("toman")}{priceComma(item.price, 2)}
            </p>
          </div>
          <div class="flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">{t("color")}</p>
            <p class="font-semibold text-gray-900" dir="rtl">
              {item.color} {item.size}
            </p>
          </div>
          <div class="flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">{t("count")}</p>
            <p class="font-semibold text-gray-900">{item.count}</p>
          </div>
        </div>
      ))}
      {checkoutPage && (
        <div class=" border-t border-b py-2">
          <div class="flex items-center justify-between">
            <p class="font-semibold text-gray-900" dir="rtl"></p>
            <p class="text-sm font-medium text-gray-900">هزینه دسته بندی </p>
          </div>
        </div>
      )}
      {/* <div class="mt-6 flex items-center justify-between">
        <p class="text-sm font-medium text-gray-900">مالیات ارزش افزوده</p>
        <p dir="rtl" class="text-xl font-semibold text-gray-900">
          0 {t("toman")}
        </p>
      </div> */}

      <div class="mt-2 flex items-center justify-between">
        <p class="text-sm font-medium text-gray-900">{t("total")}</p>
        <p dir="rtl" class="text-2xl font-semibold text-gray-900">
          {priceComma(total, 2)} {t("toman")}
        </p>
      </div>
      {!checkoutPage && (
        <div>
          <Link
            to="/checkout"
            class="mt-4 w-full block text-center rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
          >
            {t("complete-buy")}
          </Link>
          <Link
            to="/"
            class="mt-2 w-full block text-center rounded-md bg-white px-6 py-3 font-medium text-black"
          >
            {t("wants-buy-more")}
          </Link>
        </div>
      )}
    </div>
  );
};

export default CheckoutPrice;
