import About from "components/About";
import Gallery from "components/common/Gallery";
import Contact from "components/Contact";
import ContactForm from "components/ContactForm";
import Footer from "components/Footer";
import Header from "components/header";
import PathShow from "components/PathShow";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="font-[Yekan] mt-[99px] md:mt-[83px]">
        <Header />
        <PathShow url="menu-contact" image="/location (1).jpg" />
        <ContactForm />
        <Footer />
      </div>
    </>
  );
};

export default ContactPage;
