import React, { useContext, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import { CDN_BASE_URL } from "../config";

import { CartContext } from "../context/CartContext";
import { FaExclamationCircle, FaTrash } from "react-icons/fa";

import CheckoutPrice from "../components/CheckoutPrice";
import { Helmet } from "react-helmet";
import Header from "components/header";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import { priceComma } from "utils/priceComma";

const CartPage = () => {
  const [loading, setloading] = useState();
  const { cart, setCart } = useContext(CartContext);

  const total = cart.reduce((accumulator, current) => {
    return accumulator + current.price * current.count;
  }, 0);
  function handleDelete(e) {
    const newCart = cart.filter((c) => c.id !== e.id);

    setCart(newCart);
  }

  const [t] = useTranslation("global");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"EMPORIUM POINT | سبد خرید"}</title>
        <meta name="og:title" content={"EMPORIUM POINT | سبد خرید"} />
      </Helmet>
      <div className="font-[Yekan] mt-[99px] md:mt-[83px]">
        <Header />
        <div className=" overflow-hidden  py-10  bg-white  min-h-[80vh]">
          <div class="grid px-[8px] sm:px-10 lg:grid-cols-3 lg:px-20 xl:px-32 gap-[1rem]">
            <div className="order-2 lg:order-1">
              <CheckoutPrice total={total} />
            </div>

            <div class=" pt-8 lg:col-span-2 order-1 lg:order-2">
              <p class="text-xl font-medium">{t("cart")}</p>
              {cart?.length ? (
                cart.map((item) => (
                  <div class="mt-[1rem] space-y-3 rounded-lg border bg-white px-[8px] py-[16px] sm:px-6">
                    <div class="flex flex-col rounded-lg bg-white sm:flex-row">
                      <span className="flex flex-col items-center justify-center">
                        <Link>
                          <img
                            class="m-2 h-24 w-28 rounded-md border object-cover object-center"
                            src={CDN_BASE_URL + item?.img}
                            alt=""
                          />
                        </Link>
                      </span>

                      <div class="flex w-full flex-col px-4 py-4 items-end">
                        <span className="mb-3">
                          <FaTrash
                            onClick={() => {
                              handleDelete(item);
                            }}
                            color="red"
                            className="cursor-pointer"
                          />
                        </span>
                        <span class="font-semibold">{item?.name}</span>
                        <p class="text-lg font-bold">
                          {item.color} {item.size}
                        </p>
                        <p class="text-lg font-bold">{item?.count}x</p>
                        <p class="text-lg font-bold">
                          {priceComma(item.price, 2)}
                          {t("toman")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="w-full h-full text-center  text-[24px] lg:text-[32px] font-bold py-6">
                    {t("cart-is-empty")}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
