import { httpReauest } from "api/api";
import { CartContext } from "context/CartContext";
import { Home, SearchNormal1, SearchZoomIn, ShoppingCart } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";
import { FaAlignJustify, FaAngleDown } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import CartModal from "./common/CartModal";
import SearchModal from "./common/SearchModal";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [searchShow, setsearchShow] = useState(false);
  const [data, setData] = useState([]);

  const location = useLocation();

  const { cartshow, setCartshow, cart } = useContext(CartContext);

  const [t, i18n] = useTranslation("global");
  const handleChangeLanguge = (e) => {
    i18n.changeLanguage(e);
  };

  useEffect(() => {
    if (i18n.language == "fa" || i18n.language == "ar") {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
  }, [i18n.language]);

  useEffect(() => {
    // getIp();
    getData();
  }, []);

  async function getData() {
    const res = await httpReauest("GET", "/category", {}, {});
    setData(res.data.data);
  }

  async function getIp() {
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();
    if (data.country_code === "IR") {
      i18n.changeLanguage("fa");
    } else {
      i18n.changeLanguage("en");
    }
  }
  return (
    <div dir="ltr" class=" nav-bar bg-transparent">
      <span
        className={`bg-[#f5f5f7] shadow h-[120%] fixed w-full overflow-hidden lg:w-[300px] z-30 duration-300 ${
          cartshow >= 1 ? "right-[0%]" : "right-[-100%]"
        }`}
      >
        <CartModal setModal={setCartshow} />
      </span>
      <span
        className={`bg-[#f5f5f7] shadow h-[120%] fixed w-full overflow-hidden lg:w-[100%] z-30 duration-300 ${
          searchShow >= 1 ? "right-[0%]" : "right-[-100%]"
        }`}
      >
        <SearchModal setModal={setsearchShow} />
      </span>
      <div className="bg-[#3d3c38] network_bg">
        <nav class="navbar navbar-expand-lg container-xxl navbar-light py-1 px-4 z-10">
          <Link className="flex justify-center w-full sm:w-[180px]" to="/">
            <img
              style={{ filter: "brightness(0) invert(1)" }}
              class="img-fluid w-[180px]  object-contain "
              src="/logo.png"
              alt="Icon"
            />
          </Link>
          <span
            onClick={() => (show2 ? setShow2(false) : setShow2(true))}
            className="flex cursor-pointer text-[14px] relative mx-[1rem]"
          >
            <span
              className="flex justify-center items-center gap-2 text-white"
              style={{ whiteSpace: "nowrap", fontSize: "14px" }}
            >
              {i18n.language == "en" && <Flag country="US" />}
              {i18n.language == "ru" && <Flag country="RU" />}
              {i18n.language == "ar" && <Flag country="SA" />}
              {i18n.language == "fa" && <Flag country="IR" />}
              {i18n.language == "en" && "English - EN"}
              {i18n.language == "ru" && "RU - Russian"}
              {i18n.language == "ar" && "AR - العربیة"}
              {i18n.language == "fa" && "FA - فارسی "}
              <FaAngleDown className="" size={15} />
            </span>

            {show2 && (
              <span className={`drop_down h-fit`}>
                <span
                  onClick={() => handleChangeLanguge("en")}
                  className=" cursor-pointer flex items-center gap-2 w-full  py-2 px-3 whitespace-nowrap"
                >
                  <Flag country="US" />
                  <span> EN - English </span>
                </span>
                <span
                  onClick={() => handleChangeLanguge("fa")}
                  className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
                >
                  <Flag country="IR" />
                  <span> FA - فارسی </span>
                </span>
                <span
                  onClick={() => handleChangeLanguge("ar")}
                  className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
                >
                  <Flag country="SA" />
                  <span> AR - العربیة </span>
                </span>
                <span
                  onClick={() => handleChangeLanguge("ru")}
                  className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
                >
                  <Flag country="RU" />
                  <span> RU - Russian</span>
                </span>
              </span>
            )}
          </span>
          <button
            type="button"
            class="navbar-toggler"
            onClick={() => setShow(!show)}
            style={{borderColor:"#ffffff50"}}
          >
            <FaAlignJustify color="#fff"/>
          </button>
          <div
            class={`${
              !show ? "max-h-0 " : "max-h-96"
            } lg:max-h-96 duration-500 navbar-collapse overflow-hidden lg:overflow-visible`}
            id="navbarCollapse"
          >
            <div class="navbar-nav flex-col flex ltr:items-start justify-center w-full rtl:items-end ml-auto rtl:lg:flex-row-reverse ltr:lg:flex-row">
              {data?.map((cat) => (
                <Link
                  to={`/category/${cat?.url}`}
                  class={`nav-item nav-link ${
                    location.pathname === `/category/${cat?.url}`
                      ? "active"
                      : ""
                  }`}
                >
                  {cat?.name}
                </Link>
              ))}

              <Link
                to="/contact"
                class={`nav-item nav-link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                {t("menu-contact")}
              </Link>
              <Link
                to="/about"
                class={`nav-item nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
              >
                {t("menu-about")}
              </Link>
            </div>
            <span class="navbar-brand lg:flex items-center justify-end text-center hidden gap-3 relative overflow-visible">
              <SearchNormal1
                color="#fff"
                className="cursor-pointer"
                onClick={() => setsearchShow(!cartshow)}
              />
              <span
                onClick={() => setCartshow(!cartshow)}
                className="relative cursor-pointer"
              >
                {cart.length ? (
                  <span className="absolute left-2/4 bottom-1/3 w-[23px] h-[23px] flex justify-center items-center  bg-red-500 text-white rounded-full text-[12px] z-10">
                    {cart.length}
                  </span>
                ) : null}
                <ShoppingCart color="#fff" />
              </span>
            </span>
          </div>
        </nav>
      </div>
      <div className="fixed bottom-0 left-0 w-full flex justify-between bg-[#3d3c38] px-4 py-3 lg:hidden text-white">
        <SearchNormal1
          className="cursor-pointer"
          onClick={() => setsearchShow(!cartshow)}
        />
        <Link to={"/"}>
          <Home />
        </Link>
        <span
          onClick={() => setCartshow(!cartshow)}
          className="relative cursor-pointer"
        >
          {cart.length ? (
            <span className="absolute left-2/4 bottom-1/3 w-[23px] h-[23px] flex justify-center items-center  bg-red-500 text-white rounded-full text-[12px] z-10">
              {cart.length}
            </span>
          ) : null}
          <ShoppingCart />
        </span>
      </div>
    </div>
  );
};

export default Header;
